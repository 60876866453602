<template>
  <div class="app">
    <!-- 头 -->
    <div class="app-top">
      <div class="top_1 flex">
        <div class="left">
          <el-image :src="require('assets/logo.png.png')" class="image_1" />
          <p>{{ appName }}</p>
        </div>
        <div class="left">
          <i
            class="el-icon-document-remove"
            style="font-size: 20px; color: #fff"
          ></i>
          <p class="p1">《用户使用协议》</p>
        </div>
      </div>
    </div>
    <!-- 中 -->
    <div class="app-center">
      <!--1-->
      <div class="one flex">
        <div class="one_left">
          <h3>{{ title }}</h3>
          <p>为您提供便捷的家政服务，让您的生活更加轻松。</p>
          <el-button type="success">下载体验</el-button>
          <div style="display: flex">
            <div class="qrcode" v-for="(img, i) in imags" :key="i">
              <el-image :src="img.img" alt="截图" class="qr-code"></el-image>
              <div class="text">{{ img.title }}</div>
            </div>
          </div>
        </div>
        <el-image
          :src="require('assets/7.png')"
          style="width: 450px; height: 410px"
        />
      </div>
      <!-- 2 -->
      <div class="two flex">
        <div style="display: flex">
          <div class="qrcode" v-for="(img, i) in imags1" :key="i">
            <el-image :src="img" alt="截图" class="qr-code"></el-image>
          </div>
        </div>
        <div class="two_tigh">
          <p>
            我们的App拥有<strong style="font-size: 20px"
              >丰富的家政服务选择</strong
            >，
          </p>
          <p>
            包括<strong style="font-size: 20px; color: rgba(70, 162, 87, 1)"
              >清洁、保姆、管家</strong
            >等服务。
          </p>
          <p>通过我们的平台，您可以轻松找到可靠的家政服务人员。</p>
        </div>
      </div>
      <!-- 品牌文化 -->
      <div class="three">
        <el-image :src="require('assets/品牌.png')" class="image_2" />
      </div>
      <!-- 亿嘉欣共享家政简介 -->
      <div class="fours flex">
        <div class="fours_left">
          <h3>亿嘉欣共享家政简介</h3>
          <p>
            亿嘉欣共享家政通过互联网大数据系统，帮助天下的家居服务公司以及众多家政从业人员，为消费者提供家庭全品类的上门服务。
          </p>
          <p>
            公司用创新思维，突破传统服务模式，做创新服务，解决行业痛点：从业人员收入无保障，行业不被尊重，收费无标准，公司招工难、管理难、溜单严重、获客成本高，消费者消费无保障，无监督管理机制等，最终做到多方共赢。
          </p>
          <p>
            解决传统家政行业的现状，从家庭服务入手，解决家庭需求的一站式上门服务，通过社区
            <span>“幸福家庭服务中心”</span>
            就近用人，解决社区人员的就业创业，以人性化、系统化、家庭化服务模式，零距离的居家服务体验，用互联网+大数据分析，细化市场，精细化服务，融入资本思维，人人参与，人人持股。大众创业，万众创新，实现共同富裕，共建和谐社会主义中国梦。
          </p>
        </div>
        <el-image :src="require('assets/8.png')" class="image_3" />
      </div>
      <!-- 亿嘉欣缘起 -->
      <div class="five">
        <h2>亿嘉欣缘起</h2>
        <div class="flex">
          <el-image :src="require('assets/创始人.png')" class="image_4" />
          <div class="five_right">
            <p>
              亿嘉欣共享家政创始人胡礼菊原属于东嘉保洁家政有限公司董事长，东嘉保洁在成都已经深耕家政行业8年时间，由于看到家政行业的服务良莠不齐，消费者很难获得满意的服务，家政公司获得订单的成本又极其高，家政工人的收入非常不稳定，淡旺季的区别非常大，同时为响应国家“打造幸福家庭，共同富裕”的号召，胡总以及她的团队通过多年对家政行业的积累和沉淀，以及对市场的深度研究。决定解决以下家政行业的五大问题:
            </p>
            <div class="xdd">第一、提高家政行业的服务水平</div>
            <div class="xdd">第三、提高家政行业的收入水平</div>
            <div class="xdd">第四、提高家政行业的信任问题</div>
            <div class="xdd">第五、让每个人的命运掌握在自己手里</div>
            <p>
              由此就创造了亿嘉饮共享家政这个平台，重过平台化数字化的精细运营可以让消费者享受到适的居住环境，并让合作的方都能获得共赢的结果由此也让亿万家庭都欣欣向荣，兴旺发达，这就亿嘉欣的由来，也是胡总的初心。
            </p>
          </div>
        </div>
      </div>
      <!-- 更多服务内容 -->
      <div class="six">
        <h2>更多服务内容</h2>
        <div style="display: flex; flex-wrap: wrap;justify-content: space-between;">
          <div class="qrcode" v-for="(img, i) in imags2" :key="i">
            <el-image :src="img" alt="截图" class="qr-code"></el-image>
          </div>
        </div>
      </div>
      <!-- 产品展示 -->
      <div class="six">
        <h2>产品展示</h2>
        <div style="display: flex; flex-wrap: wrap;justify-content: space-between;">
          <div class="image4" v-for="(img, i) in imags3" :key="i">
            <el-image :src="img" alt="截图" class="image_4"></el-image>
          </div>
        </div>
      </div>
    </div>
    <!-- 备案 -->
    <div class="app-end">
      <div class="text">
        四川亿嘉欣家政服务有限公司 版权所有©蜀ICP备2021013067号-3A
      </div>
      <div class="text">
        联系我们：3471768870@qq.com 18613224941&nbsp;&nbsp;&nbsp; 法律声明
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appName: "亿嘉欣共享家政平台",
      title: "亿嘉欣共享家政平台App",
      imags: [
        { img: require("assets/nav.png"), title: "小程序" },
        { img: require("assets/6.png"), title: "app" },
      ],
      imags1: [
        require("assets/ren3.png"),
        require("assets/ren2.png"),
        require("assets/ren1.png"),
      ],
      imags2: [
        "https://image.yjxgx.com/qiniu/image_b6f56ccd5830f5bff80e794f0b0a4dc2.jpg",
        "https://image.yjxgx.com/qiniu/image_a8ff2d6e6c5d44ec6e9e9be324570121.jpg",
        "https://image.yjxgx.com/qiniu/image_cfc9b505f2bec68b3ed338b2120f1592.jpg",
        "https://image.yjxgx.com/qiniu/image_9c8f79f9467be420387873c8ce8ac7e8.jpg",
        "https://image.yjxgx.com/qiniu/image_3d17ed3feb54c8d78a166af214e5f028.jpg",
        "https://image.yjxgx.com/qiniu/image_b99905c0ddf497625ea4b0ef97297572.jpg",
        "https://image.yjxgx.com/qiniu/image_4b6bc1b60f1ec3d76eb41f4292258c04.jpg",
        "https://image.yjxgx.com/qiniu/image_44058fb78372cb5474ed344b5c568585.jpg",
      ],
      imags3: [
        "https://img.js.design/assets/img/65bc5003a3853a259e76a2cb.png#b5b77a45ac8dbf3d5cb2d92cc55ea773",
        "https://img.js.design/assets/img/65bc5032a3853a259e76ace6.png#e98e4d94fce5d649aed4cac84c52fe6e",
        "https://img.js.design/assets/img/65bc514ce4e8680d93f2d8d8.png#888d13900f9036f5d287d14179e3053a",
        "https://img.js.design/assets/img/65bc50c4352589f4b68ac53e.png#7565b628445e8e4861d3e8037bd24363",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.app {
  // // width: 1920px;
  // margin: 0 auto;
  background: #fff;
  /* 居中显示 */
  text-align: center;
  /* 内容居中 */
  .flex {
    display: flex;
    justify-content: space-between;
  }
  &-end {
    // width: 1920px;
    height: 98px;
    padding-top: 16px;
    opacity: 1;
    background: rgba(46, 53, 76, 1);
    margin-top: 48px;
    .text {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      vertical-align: top;
    }
  }
  &-center {
    width: 1000px;
    margin: 0 auto;

    .six {
      .image4 {
        width: 240px;
        height: 452px;
        margin-right: 13px;
      }
      .image4:nth-child(4) {
        margin-right: 0 !important;
      }
      h2 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        vertical-align: top;
        margin-bottom: 32px;
      }
      .qr-code {
        width: 200px;
        height: 200px;
      }
      .qrcode {
        margin-right: 66px;
        margin-bottom: 32px;
      }
      .qrcode:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
    .five {
      margin-bottom: 48px;
      .xdd {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.8);
        text-align: left;
        vertical-align: top;
        margin-bottom: 10px;
      }
      .xdd::before {
        content: "\2022"; /* 圆点图标： • */
        margin: 0 5px;
        font-size: 20px;
      }
      h2 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        vertical-align: top;
      }
      .image_4 {
        width: 300px;
        height: 379px;
        margin-top: 14px;
      }
      .five_right {
        width: 70%;
        margin-top: 20px;
        p {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(0, 0, 0, 0.8);
          text-align: left;
          vertical-align: top;
          margin-bottom: 10px;
        }
      }
    }
    .fours {
      margin-bottom: 48px;
      .image_3 {
        width: 450px;
        height: 334px;
        margin-top: 43px;
      }
      .fours_left {
        width: 50%;
      }
      h3 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 34.75px;
        color: rgba(0, 0, 0, 0.8);
        text-align: left;
        vertical-align: top;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(0, 0, 0, 0.8);
        text-align: left;
        margin-bottom: 20px;
        span {
          color: rgba(70, 162, 87, 1);
        }
      }
    }
    .three {
      margin-bottom: 72px;
      .image_2 {
        width: 1023px;
        height: 403px;
      }
    }
    .two {
      margin-bottom: 48px;
      .qr-code {
        width: 144px;
        height: 240px;
        margin-right: 10px;
      }
      .two_tigh {
        margin-top: 72px;
        p {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 32px;
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .one {
      margin-top: 58px;
      margin-bottom: 48px;
      .one_left {
        margin-top: 54px;
        width: 50%;
        margin-left: 30px;
        h3 {
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 34.75px;
          color: rgba(0, 0, 0, 0.8);
          text-align: left;
          vertical-align: top;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(0, 0, 0, 0.8);
          text-align: left;
          vertical-align: top;
          margin-bottom: 10px;
        }
        :deep .el-button--success {
          width: 128px;
          height: 40px;
          opacity: 1;
          background: rgba(67, 207, 124, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 10px 10px 10px;
          margin-bottom: 30px;
        }
        .qr-code {
          width: 120px;
          height: 120px;
          margin-right: 48px;
        }

        .text {
          width: 120px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(0, 0, 0, 1);
          vertical-align: top;
        }
      }
    }
  }

  &-top {
    height: 60px;
    opacity: 1;
    background: rgba(70, 162, 87, 1);
   
    .top_1{
      width: 1000px;
      margin: 0 auto;
    }
    .left {
      height: 60px;
      display: flex;
      align-items: center;
    }
    .image_1 {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      margin-left: 5px;
      color: rgba(255, 255, 255, 1);
    }
    .p1 {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
      vertical-align: top;
    }
  }
}
</style>